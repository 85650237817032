<template>
  <div class="list">
    <list-template
        :current-page="page"
        :loading="loading"
        :search-config="searchConfig"
        :table-config="tableConfig"
        :table-data="tableData"
        :total="total"
        @onChangePage="changeCurrentPage"
        @onHandle="tableHandle"
        @onOneClickThrough="onOneClickThrough"
        @onReset="search = null"
        @onSearch="onSearch"
    ></list-template>
    <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="MailboxDialog" title="一键通过" width="30%">
      <div style="color: #222222;text-align: center;margin: 36px">点【全部通过】后，将通过所有的待审核的数据，请慎重考虑。</div>
      <div style="display: flex;justify-content: center">
        <el-button style="margin-right: 32px" type="primary" @click="onMailboxSave">全部通过</el-button>
        <el-button @click="MailboxDialog = false">我点错了</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ListTemplate from "@/components/pages/List.vue";

export default {
  components: {ListTemplate},
  _config: {"route": {"path": "list", "meta": {"title": "列表", "keepAlive": true}}},
  data() {
    return {
      loading: true,
      MailboxDialog: false,
      searchConfig: [
        {
          tag: 'select',
          placeholder: '请选择校区',
          prop: 'school_id',
          options: [],
          change: (val, search) => {
            this.search = {...search}
          }
        },
        {
          prop: 'clerk_name',
          placeholder: '搜索教师姓名'
        }
      ],
      tableConfig: [
        {
          prop: 'checked',
          label: '审核状态',
          render(row) {
            let checked = parseInt(row.checked)
            return checked === 0 ? '待审核' : checked === 1 ? '通过' : '未通过'
          }
        },
        {
          prop: 'school_name',
          label: '校区'
        },
        {
          prop: 'clerk_name',
          label: '教师姓名'
        },
        {
          prop: 'subject_name',
          label: '教学科目'
        },
        {
          prop: 'examine_time',
          label: '审核时间'
        },
        {
          prop: 'handle',
          label: '操作',
          width: '120rem',
          handle: true,
          buttons: (row) => {
            let checked = parseInt(row.checked)
            return checked == 0 ? [{type: "approve", text: "审核"}] : [{type: "view", text: "查看"}]
          }
        }
      ],
      search: null,
      tableData: [],
      total: 0,
      page: 1
    }
  },
  methods: {
    getOption() {
      const obj = this.$store.getters.userInfo.school_arr
      let options = []
      Object.keys(obj).forEach(key => {
        options.push({label: obj[key], value: key})
      })
      this.searchConfig[0].options = options
    },
    tableHandle(row) {
      this.$router.push({path: './details', query: {type: row.checked, id: row.id}})
    },
    // 分页被更改
    changeCurrentPage(e) {
      this.page = e
      this.getData()
    },
    // 获取数据
    getData() {
      let {search, page} = this
      this.loading = true
      this.$_axios2.get('api/teacher/account/examine', {params: {...search, page}}).then(res => {
        this.tableData = res.data.data.list
        this.total = res.data.data.page.total
      })
          .finally(() => (this.loading = false))
    },
    // 搜索功能
    onSearch(val) {
      this.page = 1
      this.search = val
      this.getData()
    },
    onOneClickThrough() {
      this.MailboxDialog = true
    },
    onMailboxSave() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.$_axios2.get('api/teacher/account/checked-all', {params: {ids: this.tableData.map(item => item.id).join(',')}}, {logic: 1}).then(res => {
        this.$store.commit("setPage", 1);
        this.$message.success(res.data.msg)
        this.getData()
        this.MailboxDialog = false
        loading.close();
      })
    },
  },
  mounted() {
    this.getData()
    this.getOption()
  },
  activated() {
    this.getData()
  }
}
</script>

<style scoped></style>
